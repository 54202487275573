<template>
  <test 
    :o_Fields="o_Fields"
    :i_TestID="i_TestID"
    :i_LangID="i_LangID"
    :i_Translation="i_Translation"
    :i_TestTypeID="i_TestTypeID"
    :s_Number="s_Number"
  ></test>
</template>

<script>
import { get, post, getFile } from "../../../worker/worker-api"
import { mapGetters, mapActions } from "vuex"
import Test from '../../../components/SMT/Test.vue'

export default {
  name: "TestView",
  metaInfo() {
    return {
      // title will be injected into parent titleTemplate
      title: this.$t("general.Mini Cases"),
    };
  },
  components: {
    Test
  },
  props: {
    i_TestID: {
      type: Number,
    },
    i_LangID: {
      type: Number,
    },
    i_Translation: {
      type: Number,
      default: 0,
    },
    i_TestTypeID: {
      type: Number,
      default: 0,
    },
    s_Number: {
      type: Number,
    },
  },
  data() {
    return {
      Lb_GetDocDialog: false,
      Lb_IsQSK: false,
      Lb_Translator: false,
      Lb_Deleting: false,
      Lb_Isloded: false,
      dialog: false,
      Li_QuestionIDToBeDeleted: null,
      search: "",
      Lo_Questions: [],
      b_ShowArchived: false,
      o_Fields: {
        status: true,
        author: true,
        released: true,
        number: true,
        advancedModule: false,
        electiveModule: false,
        oldNumber: false,
        tag: true,
        designation: true,
        description: true,
        role: false,
        initialSituation: true,
        statement: true,
        solutionSituation: true,
        solutionConclusions: true,
        solutionMeasures: true,
        questions: false
      }
    };
  },
  computed: {
    ...mapGetters(['getRoles', 'getUserID','getFGQuestionsPerPage', 'getLastSearchFG']),
    headers() {
      return [
        {
          text: this.$t("general.number_abbreviation"),
          align: "start",
          sortable: true,
          value: "s_Number",
        },
        {
          text: this.$t("general.advanced_module_abbreviation"),
          value: "s_AdvancedModule",
          sortable: true,
        },
        {
          text: this.$t("general.elective_module_abbreviation"),
          value: "s_ElectiveModule",
          sortable: true,
        },
        { text: this.$t("general.short_description"), value: "s_Designation" },
        { text: "De", value: "s_De" },
        { text: "Fr", value: "s_Fr" },
        { text: "IT", value: "s_It" },
        {
          text: this.$t("general.release"),
          value: "i_Released",
          sortable: true,
        },
        { text: this.$t("general.status"), value: "s_Status" },
        { text: this.$t("general.actions"), value: "action", sortable: false, },
      ];
    }, 
    a_QuestionsToDisplay () {
      const La_Questions = this.Lo_Questions.map(question => ({
        ...question, b_QuestionBlocked: (question.i_InEditingPersonID !== 0 
            && question.i_InEditingPersonID !== null 
            && parseInt(this.getUserID) !== question.i_InEditingPersonID) ? true : false
      }))
      if (this.b_ShowArchived) return La_Questions
      return La_Questions.filter(question => question.s_Status !== 5)
    },
  },
  watch: {
    "$i18n.locale": function () {
      //this.getTestslist();
    },
    search(val) {
      this.setLastSearchFG(val)
    }
  },
  methods: {
    ...mapActions(['changeQuestionsPerPage', 'setLastSearchFG']),
    GetOptions(Ai_ItemsPerPage){
      let Lo_Options = {
        i_itemsPerPage: Ai_ItemsPerPage,
        i_QuestionsTypID: 2,//2 = FG
      } 
      this.changeQuestionsPerPage(Lo_Options);
    },
    getXlsReport(Ai_TestTypeID) {
      this.Lb_GetDocDialog = true;
      getFile(
        `${process.env.VUE_APP_SMT_API_URL}/data/${Ai_TestTypeID}/xlsreport`,
        {}
      )
        .then((response) => {
          let blob = new Blob([response], {
            type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
          });
          let url = URL.createObjectURL(blob);
          const a = document.createElement("a");
          a.style.display = "none";
          a.href = url;
          // the filename
          a.download = "Liste der Fragen.xlsx";
          document.body.appendChild(a);
          a.click();
          window.URL.revokeObjectURL(url);
          this.Lb_GetDocDialog = false;
        })
        .catch((error) => {
          console.log(error);
          this.Lb_GetDocDialog = false;
        });
    },
    getDocxReport(Ai_TestID, Ai_LangID, As_Number) {
      let Ls_Lang = "";
      switch (Ai_LangID){
        case 1:
          Ls_Lang = "de";
          break;
        case 2:
          Ls_Lang = "fr";
          break;
        case 3:
          Ls_Lang = "it";
          break;  
      }
      this.Lb_GetDocDialog = true;
      getFile(
        `${process.env.VUE_APP_SMT_API_URL}/data/${Ai_TestID}/docxreport/${Ai_LangID}`,
        {}
      )
        .then((response) => {
          let blob = new Blob([response], {
            type: "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
          });
          let url = URL.createObjectURL(blob);
          //window.open(url);
          const a = document.createElement("a");
          a.style.display = "none";
          a.href = url;
          // the filename
          a.download = `${As_Number}_${Ls_Lang}.docx`;
          document.body.appendChild(a);
          a.click();
          window.URL.revokeObjectURL(url);
          this.Lb_GetDocDialog = false;
        })
        .catch((error) => {
          console.log(error);
          this.Lb_GetDocDialog = false;
        });
    },
    getReport(Ai_TestID, Ai_LangID,As_Number) {
      let Ls_Lang = "";
      switch (Ai_LangID){
        case 1:
          Ls_Lang = "de";
          break;
        case 2:
          Ls_Lang = "fr";
          break;
        case 3:
          Ls_Lang = "it";
          break;  
      }
      this.Lb_GetDocDialog = true;
      getFile(
        `${process.env.VUE_APP_SMT_API_URL}/data/${Ai_TestID}/report/${Ai_LangID}`,
        {}
      )
        .then((response) => {
          let blob = new Blob([response], { type: "application/pdf" });
          let url = URL.createObjectURL(blob);
          const a = document.createElement("a");
          a.style.display = "none";
          a.href = url;
          // the filename
          a.download = `${As_Number}_${Ls_Lang}.pdf`;
          document.body.appendChild(a);
          a.click();
          window.URL.revokeObjectURL(url);
          this.Lb_GetDocDialog = false;
        })
        .catch((error) => {
          console.log(error);
          this.Lb_GetDocDialog = false;
        });
    },
    routeToQuestionsHistory(Ai_TestID) {
      this.$router.push({
        name: "questions-history",
        params: { i_TestID: Ai_TestID },
      });
    },
    routeToQuestionsComments(Ai_TestID) {
      this.$router.push({
        name: "questions-comments",
        params: { i_TestID: Ai_TestID, i_TestTypeID: 2 },
      });
    },
    routeToNewQuestion() {
      this.$router.push({ name: "new-question", params: { Pi_TestTypeID: 2 } }); //2 = FG
    },
    routeToEditQuestions(Ai_TestID, Ai_LangID, Ai_Translation, As_Number) {
      this.$router.push({
        name: "edit-question",
        params: {
          i_TestID: Ai_TestID,
          i_LangID: Ai_LangID,
          i_Translation: Ai_Translation,
          Pi_TestTypeID: 2,
          Ps_Number: As_Number,
        },
      });
    },
    customSearch(value, search, item) {
      return Object.values(item).some(
        (v) =>
          (v && v.toString().toLowerCase().includes(search.toLowerCase())) ||
          v.toString().toUpperCase().includes(search.toUpperCase())
      );
    },
    deleteQuestionDialog(Ai_QuestionID) {
      this.dialog = true;
      this.Li_QuestionIDToBeDeleted = Ai_QuestionID;
    },
    deleteQuestion() {
      this.Lb_Deleting = true;
      post(`${process.env.VUE_APP_SMT_API_URL}/teacher/delete-question`, {
        i_TestID: this.Li_QuestionIDToBeDeleted,
      })
        .then((response) => {
          this.getTestslist();
          this.Lb_Deleting = false;
          this.dialog = false;
          this.Li_QuestionIDToBeDeleted = null;
          this.getTestslist();
        })
        .catch((errors) => {
          console.log("submit Worker error: ", errors);
          this.Lb_Deleting = false;
          this.dialog = false;
          this.Li_QuestionIDToBeDeleted = null;
        });
    },
    copyRow: function (Ai_TestID) {
      this.Lb_Isloded = true;
      post(`${process.env.VUE_APP_SMT_API_URL}/teacher/set-question-copy`, {
        i_TestID: Ai_TestID,
      })
        .then((response) => {
          this.getTestslist();
          this.Lb_Isloded = false;
        })
        .catch((errors) => {
          console.log("submit Worker error: ", errors);
        });
    },
    getTestslist() {
      this.Lb_Isloded = true;
      get(`${process.env.VUE_APP_SMT_API_URL}/teacher/questions-list`, {
        i_QuestionstypID: 2, //FG
        i_PersonID: this.getUserID,
        s_Lang: this.$i18n.locale,
      })
        .then((response) => {
          if (response.status === 401) {
            this.signOut();
            this.$smt.logout();
            return;
          }
          this.Lo_Questions = response.a_Questions;
          this.Lb_Isloded = false;
          for (let i = 0; i < this.Lo_Questions.length; i++) {
            for (let j = 0; j < this.$t("a_Tags").length; j++) {
              if (this.Lo_Questions[i].s_Tag === this.$t("a_Tags")[j].s_Id) {
                this.Lo_Questions[i].s_Tag = this.$t("a_Tags")[j].s_Text;
              }
            }
          }
        })
        .catch((error) => {
          console.log("Worker error: ", error);
        });
    },
    getTranslatorRole() {
      let result = this.getRoles.filter(
        (Roles) => Roles.s_Role == "translator" || Roles.s_Role == "qsk"
      );
      if (result.length > 0) {
        this.Lb_Translator = true;
      }
    },
    getQSKRole() {
      let result = this.getRoles.filter((Roles) => Roles.s_Role == "qsk");
      if (result.length > 0) {
        this.Lb_IsQSK = true;
      }
    },
    getStatusLabel(Ai_StatusID) {
      switch (Ai_StatusID) {
        case 0:
          return {
            color: 'warning',
            label: this.$t('general.draft')
          }
          break;
        case 1:
          return {
            color: 'warning',
            label: 'QSK'
          }
          break;
        case 2:
          return {
            color: 'warning',
            label: this.$t('general.in_translation')
          }
          break;
        case 3:
          return {
            color: 'success',
            label: this.$t('general.definitely')
          }
          break;
        case 4:
          return {
            color: 'teal lighten-1',
            label: this.$t('general.proofreading')
          }
          break;
        case 5:
          return {
            color: 'grey',
            label: this.$t('general.archived')
          }
          break;
        default:
          break;
      }
    },
    getEditionProps(Ao_Question) {
      if (Ao_Question.b_QuestionBlocked) return {
        color: 'grey',
        label: this.$t('general.in_editing_by') + Ao_Question.s_InEditingName
      }
      else return {
        color: 'success',
        label: this.$t("general.edit")
      }
    },
  },
  created() {
    //this.getTestslist();
    //this.getTranslatorRole();
    //this.getQSKRole();
    //this.search = this.getLastSearchFG
  },
};
</script>
<style lang="scss" scoped>
::v-deep .table-one {
  thead.v-data-table-header {
    tr {
      &:hover {
        background-color: #f2f3f8;
      }
      th {
        span {
          font-size: 14px;
          color: #304156;
        }
      }
    }
    tr {
      td {
        padding-bottom: 20px;
        padding-top: 20px;
      }
    }
  }
  tbody {
    tr {
      &:hover {
        background-color: #f2f3f8 !important;
      }
    }
  }
}
</style>
